import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Originally appeared on`}</em>{` `}<a parentName="p" {...{
        "href": "https://chatbotsmagazine.com/get-dashbot-analytics-for-your-chatfuel-facebook-bot-in-five-minutes-a2f401ee8483#.70eoi6mhj"
      }}><em parentName="a">{`Chatbots Magazine`}</em></a><em parentName="p">{`.`}</em></p>
    <p>{`Here’s how:`}</p>
    <p>{`First, go to `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/"
      }}>{`https://www.dashbot.io/`}</a>{` and create an account. Create a Facebook bot, and save the API KEY. This will be the `}<strong parentName="p">{`DASHBOT_API_KEY`}</strong>{` that you will use below.`}</p>
    <p>{`Then, go to `}<a parentName="p" {...{
        "href": "https://developers.facebook.com/apps/"
      }}>{`https://developers.facebook.com/apps/`}</a>{` and “Add a New App”. Choose Category “Apps for Messenger”.`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2017/05/07125948/3c996-1ncl4eotoia64nt0krc09iq.png)
    </figure>
In “Token Generation” select the Page that your Bot is associated with, allow
page permissions for this app. Save this token, this is the **FACEBOOK\_TOKEN**
you will use below.
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2017/05/07125947/0_cf_yllko8YkSCq8l.png)
    </figure>
Click on “Setup Webhooks”
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2017/05/07125947/0_fzrbzRQD_u5d5dga.png)
    </figure>
Enter your Callback URL:
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://tracker.dashbot.io/track?apiKey=%5BDASHBOT_API_KEY%5D&type=all&platform=facebook&v=0.8.4-fbecho&fbtoken=%5BFACEBOOK_TOKEN%5D"
        }}>{`https://tracker.dashbot.io/track?apiKey=`}<strong parentName="a">{`DASHBOT_API_KEY`}</strong>{`&`}{`type=all`}{`&`}{`platform=facebook`}{`&`}{`v=0.8.4-fbecho`}{`&`}{`fbtoken=`}<strong parentName="a">{`FACEBOOK_TOKEN`}</strong></a></li>
      <li parentName="ul">{`For Verify Token, enter “dashbot”`}</li>
    </ul>
    <p>{`Check ALL of the boxes`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2017/05/07125946/0_uHvZ9X_5l6HriiQB.png)
    </figure>
Click on “Select a Page” to subscribe your webhook to page events, and select
the page associated with your bot
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2017/05/07125945/0_KlCaZgVoXC1134dK.png)
    </figure>
Click on the “Subscribe” button. It’s blue. It should look like this:
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2017/05/07125944/0_kMaFACSQlH8jTKKV.png)
    </figure>
When you have set everything up properly, your Messenger configuration should
look something like this:
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2017/05/07125944/37a3f-1v_jtnxit5vjc5iptc3lu0g.png)
    </figure>
Do not submit this app for review. You actually do not want this app to be
publicly available, since it only serves to echo the conversation to Dashbot.
    <p>{`DONE! Go to Dashbot and look at your conversations!`}</p>
    <p><em parentName="p">{`*`}{`*`}{` Actually, this integration works with ANY Facebook bot, regardless of what tool you used to build it. So, whether you’ve used Chatfuel, Dexter, Motion.ai, Message.io, Botkit Studio, or any other platform, you can use this integration!`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      